<template>
  <div
    v-if="totalRecordsCount > limits[0]"
    class="d-flex align-items-center justify-content-between pagination"
  >
    <b-form-select
      :value="limit"
      class="pagination__limit"
      @change="onLimitChange"
    >
      <option
        v-for="item in limits"
        :key="item"
        :value="item"
      >
        {{ item }} per page
      </option>
    </b-form-select>
   
    <b-pagination
      v-if="totalRecordsCount > limit"
      class="m-0"
      align="right"
      :value="page"
      :per-page="limit"
      :total-rows="totalRecordsCount"
      @change="onPageChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    page: Number,
    limit: Number,
    totalRecordsCount: Number,
    limits: {
      type: Array,
      default: () => [10, 20, 30, 60, 80, 100]
    }
  },
  methods: {
    onPageChange(page) {
      this.$emit('onPageChange', page);
    },
    onLimitChange(limit) {
      this.$emit('onLimitChange', limit);
    }
  }
}
</script>

<style lang="sass" scoped>
.pagination
  &__limit
    width: 140px
</style>
