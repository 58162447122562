<template>
  <Layout :loading="loading">
    <EmailModalForm
      :mode="formMode"
      :modalId="modalId"
      :initialData="currentRow"
      :employeesOptions="employeesOptions"
      @onSubmit="handleEmailFormSubmit"
      @onClearForm="clearForm"
    />
    <div class="services table-page">
      <div class="table-page__header">
        <h1 class="title-1">Emails</h1>
        <b-button
          class="mr-2"
          variant="success"
          @click="handleAdd"
        >
          Add Email
        </b-button>
      </div>
      <b-card class="table-page__container">
        <b-table
          :items="list"
          :fields="fields"
          hover
          sticky-header
          class="app-table"
          :class="{ 'app-table_filter-open': isDropdownOpen }"
        >
          <template #head(email)>
            <div class="app-table__filter-input">
              <b-form-input
                :value="email"
                ref="email"
                type="text"
                placeholder="Email"
                @input="handleEmailChange"
              />
              <i
                  v-if="email"
                  class="bx bxs-x-circle app-table__filter-clear"
                  @mousedown.prevent="handleEmailChange('')"
                />
            </div>
          </template>
          <template #[`head(employee.name)`]>
            <template>
              <multiselect
                v-model="employee"
                :options="employees"
                :searchable="true"
                :show-labels="false"
                label="name"
                track-by="uid"
                placeholder="Owner"
                class="app-multiselect app-table__filter-select"
                @open="isDropdownOpen = true"
                @close="isDropdownOpen = false"
              >
                <template #clear>
                  <i
                    v-if="employee"
                    class="bx bxs-x-circle app-table__filter-clear"
                    @mousedown.prevent="employee = null"
                  />
                </template>
              </multiselect>
            </template>
          </template>
          <template #cell(index)="{ index }">
            {{ index + 1 }}
          </template>
          <template #cell(actions)="{ item }">
            <div class="d-flex align-items-center justify-content-end">
              <i
                class="bx bx-pencil text-warning font-size-20 mr-3 cursor-pointer"
                @click="onEditIconClick(item)"
              />
              <i
                class="bx bx-trash text-danger font-size-20 cursor-pointer"
                @click="onDeleteIconClick(item.id)"
              />
            </div>
          </template>
        </b-table>
        <empty-page v-if="!list.length" />
      </b-card>
      <pagination
        :page="page"
        :limit="pageLimit"
        :totalRecordsCount="totalRows"
        @onPageChange="handlePageChange"
        @onLimitChange="handleLimitChange"
      />
    </div>
  </Layout>
</template>
<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import Layout from '@/router/layouts/main.vue';
import EmptyPage from '@/components/empty-page.vue';
import EmailModalForm from '@/components/emailModalForm.vue';
import Pagination from '@/components/pagination.vue';
import {FORM_MODE} from "../../../utils/constants";

export default {
  name: 'EmailsPage',
  components: {
    Layout,
    EmptyPage,
    Pagination,
    EmailModalForm
  },
  data() {
    return {
      page: 1,
      search: '',
      pageLimit: 20,
      modalId: 'email-modal',
      currentRow: null,
      formMode: FORM_MODE.CREATE,
      isDropdownOpen: false,
      fields: [
        { key: 'index', label: '', thStyle: { minWidth: '70px', width: '70px', maxWidth: '70px' }, tdClass: 'font-weight-bold' },
        { key: 'email', thStyle: { minWidth: '250px', width: '250px' } },
        { key: 'employee.name', thStyle: { minWidth: '250px', width: '250px' } },
        { key: 'description', label: 'Description' },
        { key: 'actions', label: '', thStyle: { minWidth: '100px', width: '100px', maxWidth: '100px' } }
      ],
      email: '',
      employee: null
    }
  },
  mounted() {
    this.$store.dispatch('employees/getEmployees');
    this.$store.dispatch('employees/getActiveEmployees');
    this.$store.dispatch('emails/getEmails', this.queryParams);
  },
  computed: {
    list() {
      return this.$store.state.emails.list;
    },
    loading() {
      return this.$store.state.emails.loading;
    },
    totalRows() {
      return this.$store.state.emails.totalItems;
    },
    employees() {
      return this.$store.state.employees.list;
    },
    queryParams() {
      return {
        page: this.page,
        per_page: this.pageLimit,
        email: this.email || null,
        search: this.search ? this.search : null,
        owner_uid: this.employee?.uid ?? null
      }
    },
    ...mapGetters('employees', ['employeesOptions']),
  },
  methods: {
    handleEmailChange: debounce(async function(value) {
      this.email = value;
      this.page = 1;
      this.$refs.email.blur();
      await this.$store.dispatch('emails/getEmails', this.queryParams);
      this.$refs.email.focus();
    }, 500),
    handleAdd() {
      this.currentRow = null;
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    handlePageChange(page) {
      this.page = page;
      this.$store.dispatch('emails/getEmails', this.queryParams);
    },
    handleLimitChange(limit) {
      this.page = 1;
      this.pageLimit = limit;
      this.$store.dispatch('emails/getEmails', this.queryParams);
    },
    handleEmailFormSubmit(data) {
      if (this.formMode === FORM_MODE.CREATE) {
        this.$store.dispatch('emails/createEmail', {
          email: data.email,
          description: data.description,
          employee_uid: data.owner.value,
        }).then(() => {
          this.$store.dispatch('emails/getEmails', this.queryParams);
        });
      }
      if (this.formMode === FORM_MODE.EDIT) {
        this.$store.dispatch('emails/updateEmail',
            {
              id: this.currentRow.id,
              data: {
                email: data.email,
                description: data.description,
                employee_uid: data.owner.value,
              },
            }
        ).then((res) => {
          if (res.code === 200) {
            this.$store.dispatch('emails/getEmails', this.queryParams);
          }
        });
      }
      this.$bvModal.hide(this.modalId);
    },
    clearForm() {
      this.currentRow = null;
    },
    onEditIconClick(item) {
      this.currentRow = item;
      this.formMode = FORM_MODE.EDIT;
      this.$bvModal.show(this.modalId);
    },
    onDeleteIconClick(id) {
      this.$bvModal.msgBoxConfirm('Delete this email?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then((value) => {
          if (value) {
            this.$store.dispatch('emails/deleteEmail', id);
          }
        })
    }
  },
  watch: {
    employee() {
      this.page = 1;
      this.$store.dispatch('emails/getEmails', this.queryParams);
    }
  }
}
</script>

<style lang="scss" scoped>
.actions-buttons {
  width: 120px;
  text-align: right;
  i {
    cursor: pointer;
  }
}

.table th, .table td {
  padding-left: 10px;
  padding-right: 1px;
}

</style>
