<template>
  <b-modal
    :id="modalId"
    size="lg"
    :title="modalTitle"
    centered
    scrollable
    title-class="font-22"
    hide-footer
    @hidden="clearForm"
    @shown="setData"
  >
    <b-form
      class="email-form"
      @submit="handleSubmit"
    >
      <b-form-group
          label="Email *"
          label-cols-md="2"
          label-align="right"
          label-for="email"
      >
        <b-form-input
            id="email"
            v-model="form.email"
            type="email"
        />
      </b-form-group>
      <b-form-group
        v-if="!hideEmployee"
        label="Owner *"
        label-cols-md="2"
        label-align="right"
        label-for="owner"
      >
        <multiselect
          v-model="form.owner"
          :options="employeesOptions"
          searchable
          track-by="value"
          label="text"
        ></multiselect>
      </b-form-group>
      <b-form-group
          label="Description"
          label-cols-md="2"
          label-align="right"
          label-for="description"
      >
        <b-form-textarea
            id="description"
            v-model="form.description"
        >
        </b-form-textarea>
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          {{ btnText }}
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { FORM_MODE } from "@/utils/constants";
import {validateForm} from "@/utils/validation";

export default {
  name: 'EmailModalForm',
  comments: {
    validateForm
  },
  props: {
    modalId: String,
    initialData: Object,
    mode: String,
    employeesOptions: {
      type: Array,
      default: () => []
    },
    hideEmployee: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        email: '',
        description: '',
        owner: { value: null, text: 'Choose Employee' },
      }
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      const data = { email: this.form.email };
      if (!this.hideEmployee) {
        data.owner = this.form.owner.value;
      }
      if (validateForm(data)) {
        this.$emit('onSubmit', this.form);
      }
    },
    clearForm() {
      this.$emit('onClearForm');
      this.form.email = '';
      this.form.description = '';
      this.form.owner = { value: null, text: 'Choose Employee' };
    },
    setData() {
      this.form.email = this.initialData ? this.initialData.email : '';
      this.form.description = this.initialData ? this.initialData.description : '';
      this.form.owner = this.initialData ? { value: this.initialData.employee?.uid, text: this.initialData.employee?.name } : { value: null, text: 'Choose Employee' };
    }
  },
  computed: {
    modalTitle() {
      return this.mode === FORM_MODE.CREATE ? 'New Email' : 'Edit Email';
    },
    btnText() {
      return this.mode === FORM_MODE.CREATE ? 'Save' : 'Update';
    }
  }
}
</script>

<style lang="scss">
  .employee-form {
    .custom-control-label {
      vertical-align: middle;
    }
  }
</style>
